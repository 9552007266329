<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px', 'margin-top': '16px'}" 
        header="Beteg" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="savePatient()" 
        @hide="hideDialog()" @keyup.esc="hideDialog()">
        <div style="margin-top: 4px">
            <span class="p-field p-float-label" style="width: 18%; display: inline-block">
                <InputText id="betegtitle" v-model.trim="v$.beteg.title.$model"
                    required="true" :class="{'p-invalid': v$.beteg.title.$invalid}" autocomplete="off"
                />
                <label for="fullname">Titulus</label>
            </span>
            <span class="p-field p-float-label" style="width: 82%; display: inline-block">
                <InputText id="betegname" ref="firstEditField" v-model.trim="v$.beteg.name.$model"
                    required="true" :class="{'p-invalid': v$.beteg.name.$invalid}" autocomplete="off"
                />
                <label for="fullname">Teljes név</label>
            </span>
        </div>
        <small class="p-error" v-if="v$.beteg.name.$error">{{ errorMessages.required }}</small>
        <div class="p-field">
            <SelectButton v-model="v$.beteg.sex.$model" required="true" :class="{'p-invalid': v$.beteg.sex.$invalid }" 
                :options="optionsSex" optionLabel="value" optionValue="code"
            />
            <small class="p-error" v-if="v$.beteg.sex.$error">{{ errorMessages.required }}</small>
        </div>
        <span class="p-field-checkbox" style="margin-bottom: 1.6rem">
            <Checkbox id="beteg_insocialhome" v-model="beteg.in_social_home" :binary="true"
            />
            <label for="beteg_insocialhome">Szoc. otthonos</label>
        </span>
        <span class="p-field p-float-label" v-if="beteg.in_social_home">
            <AutoComplete v-model="beteg.social_home_selected" :suggestions="filteredSocHomes" @complete="searchSocHome($event)" field="name"
                :minLength="2" autocomplete="off" placeholder="Keresés 2 karaktertől..."
                forceSelection dropdown>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">Szociális otthon</label>
        </span>
        <span class="p-field p-float-label">
            <InputMask id="betegtaj" v-model.lazy="v$.beteg.taj.$model" mask="999-999-999"
                required="true" :class="{'p-invalid': v$.beteg.taj.$invalid}" autocomplete="off"
            />
            <label for="fullname">TAJ szám</label>
            <small class="p-error" v-if="v$.beteg.taj.$error">{{ errorMessages.required }}</small>
        </span>
        <span  class="p-float-label" v-if="!isTAJValid && errors!=null" v-bind:style="{ paddingBottom: '10px' }">
            <small class="p-invalid">{{ errors.get("TAJ") }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 30%; display: inline-block">            
            <InputMask id="birthdate" v-model.lazy="beteg.birthdate" mask="9999.99.99"
                required="true" :class="{'p-invalid': v$.beteg.birthdate.$invalid}" autocomplete="off"
            />
            <label for="birthdate">Születési dátum</label> 
            <small class="p-error" v-if="v$.beteg.birthdate.validDate.$invalid">{{ errorMessages.wrongDate }}</small>            
            <small class="p-error" v-if="v$.beteg.birthdate.required.$invalid">Kötelező!</small>
        </span>
        <span class="p-field p-float-label" style="width: 70%; display: inline-block">
            <InputText id="birthplace" v-model.trim.lazy="beteg.birthplace"
                autocomplete="off"
            />
            <label for="birthplace">Születési hely</label>    
            <small v-if="v$.beteg.birthdate.validDate.$invalid" style="color:white">.</small>  
            <small class="p-error" v-if="v$.beteg.birthdate.required.$invalid" style="opacity:0">.</small>             
        </span>
        <span class="p-field p-float-label" style="width: 18%; display: inline-block">
            <InputMask id="postcode" v-model="v$.beteg.postcode.$model" mask="9999"
                required="true" :class="{'p-invalid': v$.beteg.postcode.$invalid}" autocomplete="off"
            />
            <label for="postcode">IRSZ</label>
            <small class="p-error" v-if="v$.beteg.city.$error && !v$.beteg.postcode.$error" style="opacity:0">.</small>
            <small class="p-error" v-if="v$.beteg.postcode.$error">Kötelező!</small>
        </span>
        <span class="p-field p-float-label" style="width: 82%; display: inline-block">
            <InputText id="city" v-model.trim.lazy="v$.beteg.city.$model"
                required="true" :class="{'p-invalid': v$.beteg.city.$invalid}" autocomplete="beteg-city"
            />
            <label for="city">Város</label>
            <small class="p-error" v-if="!v$.beteg.city.$error && v$.beteg.postcode.$error" style="opacity:0">.</small>
            <small class="p-error" v-if="v$.beteg.city.$error">{{ errorMessages.required }}</small>
        </span>
        <div v-if="!isIRSZValid && errors!=null" style="padding-bottom: 10px">
            <small class="p-invalid">{{ errors.get("IRSZ") }}</small>
        </div>
        <span class="p-field p-float-label">
            <InputText id="address1" v-model.trim.lazy="v$.beteg.address1.$model"
                required="true" :class="{'p-invalid': v$.beteg.address1.$invalid}" autocomplete="off"
            />
            <label for="address1">Cím</label>
            <small class="p-error" v-if="v$.beteg.address1.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 50%; display: inline-block">
            <InputText id="tel1" v-model.trim.lazy="v$.beteg.tel1.$model" 
                required="true" :class="{'p-invalid': v$.beteg.tel1.$invalid}" autocomplete="off"
            />
            <label for="tel1">Telefonszám</label>
            <small class="p-error" v-if="v$.beteg.tel1.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 50%; display: inline-block">
            <InputText id="tel2" v-model.trim.lazy="beteg.tel2" 
                required="true" autocomplete="off"
            />
            <label for="tel2">Telefonszám 2</label>
            <small style="color: white" v-if="v$.beteg.tel1.$error">.</small>
        </span>
        <span class="p-field p-float-label">
            <Textarea id="description" v-model="beteg.note" rows="3" cols="20" style="resize: vertical"
            />
            <label for="description">Megjegyzés</label>
        </span>
        <span class="p-field p-float-label" v-if="isAddress2String">
            <InputText id="address2" v-model.trim.lazy="beteg.address2"
                autocomplete="off"
            />
            <label for="address2">Eszköz behelyezés helye</label>
        </span>
        <span class="p-field p-float-label" v-else>
            <AutoComplete v-model="beteg.address2_selected" :suggestions="filteredHospitals" @complete="searchHospital($event)" field="name"
                :minLength="2" autocomplete="beteg-address2" placeholder="Keresés 2 karaktertől névre, városra..."
                forceSelection>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">Eszköz behelyezés helye</label>
        </span>
        <span class="p-field p-float-label">
            <AutoComplete v-model="beteg.rephospital_selected" :suggestions="filteredHospitals" @complete="searchHospital($event)" field="name"
                :minLength="2" autocomplete="beteg-rephospital" placeholder="Keresés 2 karaktertől névre, városra..."
                forceSelection>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">Lejelentő kórház</label>
        </span>
        <span class="p-field p-float-label">
            <InputMask id="firstvisitdate" v-model.lazy="beteg.firstvisitdate" mask="9999.99.99"
                :class="{'p-invalid': v$.beteg.firstvisitdate.$invalid}" autocomplete="off"
            />
            <small class="p-error" v-if="v$.beteg.firstvisitdate.validDate.$invalid">{{ errorMessages.wrongDate }}</small>
            <small class="p-error" v-if="v$.beteg.firstvisitdate.required.$invalid">{{ errorMessages.required }}</small>
            <label for="firstvisitdate">Első beteglátogatás dátuma</label>
        </span>
        <span class="p-field p-float-label">
            <InputText id="prevname" v-model.trim.lazy="beteg.prevname"
                autocomplete="off"
            />
            <label for="prevname">Születési név</label>
        </span>
        <span class="p-field p-float-label">
            <InputText id="mothersname" v-model.trim.lazy="beteg.mothersname"
                autocomplete="off"
            />
            <label for="mothersname">Anyja neve</label>
        </span>
        <span class="p-field p-float-label">
            <InputText id="rel1" v-model.trim.lazy="beteg.rel1"
                autocomplete="off"
            />
            <label for="rel1">Hozzátartozó neve</label>
        </span>
        <span class="p-field p-float-label">
            <InputText id="rel1addr1" v-model.trim.lazy="beteg.rel1addr1"
                autocomplete="off"
            />
            <label for="rel1addr1">Hozzátartozó címe</label>
        </span>
        <span class="p-field p-float-label">
            <InputText id="rel1tel1" v-model.trim.lazy="beteg.rel1tel1"
                autocomplete="off"
            />
            <label for="rel1tel1">Hozzátartozó telefonszáma</label>
        </span>

        <template #footer>
            <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
            <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                v-tooltip.bottom="'ESC'" tabindex="-1"
                @click="hideDialog"/>
            <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                @click="savePatient"/>
        </template>
    </Dialog>
</template>

<script>
import PatientService from '@/service/backend/patient.service'
import HospitalService from '@/service/backend/hospital.service'
import useVuelidate from '@vuelidate/core'
import { required, helpers, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store';
import dayjs from 'dayjs'

const validDate = (value) => !helpers.req(value) || dayjs(value,'YYYY.MM.DD', true).isValid()

export default {
    props: ['show','dialogData'],
    
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },

    data() {

        const megjelenit = reactive({})
        const beteg = reactive({})
        
        return {
            User: store.getters['user/User'],
            
            errors: null,
            validIRSZ: true,
            validTAJ: true,
            megjelenit,
            errorMessages,
            submitted: false,

            beteg,

            filteredSocHomes: null,
            filteredHospitals: null,

            optionsSex: [{'code': 'M', 'value' :'Férfi'}, {'code': 'F', 'value' :'Nő'}],

        }
    },
    validations: {
        beteg: {
            title: {},
            name: { required },
            sex: { required },
            taj: { required },
            city: { required },
            postcode: { required },
            address1: { required },
            tel1: { required },
            firstvisitdate: { required, validDate },
            birthdate: { required, validDate }
        },
    },
	betegService: null,
	hospitalService: null,
	created() {
        this.betegService = new PatientService();
        this.hospitalService = new HospitalService();
        this.megjelenit = false;
	},
    methods: {
        dialogShow() {
            this.beteg.saved = false
            this.$refs.firstEditField.$el.focus()
        },
        hideDialog() {
            this.megjelenit = false
            this.$emit('update:show', false)
        },
        searchSocHome(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.betegService.getSocHomesForSelect({filters: event.query })
                .then( result => {
                    this.filteredSocHomes = result
                })
            }, 600)
        },
        searchHospital(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.hospitalService.getHospitalsForSelect({filters: event.query })
                .then( result => {
                    this.filteredHospitals = result
                })
            }, 600)
        },
        savePatient() {
            this.v$.beteg.$touch()
            if (!this.v$.beteg.$invalid) {
                let oObj = this.optionsSex.find(o => o.code == this.beteg.sex)
                this.beteg.sex_val = oObj.value
                this.beteg.social_home =  (this.beteg.social_home_selected== undefined) ? '' : this.beteg.social_home_selected.id
                this.beteg.social_home_name =  (this.beteg.social_home_selected== undefined) ? '' : this.beteg.social_home_selected.name
                this.beteg.rephospital =  (this.beteg.rephospital_selected== undefined) ? '' : this.beteg.rephospital_selected.id
                this.beteg.rephospital_name =  (this.beteg.rephospital_selected== undefined) ? '' : this.beteg.rephospital_selected.name
                if(!this.isAddress2String){
                    this.beteg.address2 =  (this.beteg.address2_selected== undefined) ? '' : this.beteg.address2_selected.id
                    this.beteg.address2_name =  (this.beteg.address2_selected== undefined) ? '' : this.beteg.address2_selected.name
                }
                this.save()
            }
        },
        async save() {

            this.submitted = true

            try{
                this.beteg.id = await this.betegService.updatePatient(this.beteg)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})

                let title = (this.beteg.title == undefined) ? '' : this.beteg.title+' '
                this.beteg.title_name = title + this.beteg.name

                this.beteg.saved = true
                this.beteg.updated = dayjs().format('YYYY.MM.DD HH:mm:ss')

                this.$emit('update:dialogData', this.beteg)

                this.hideDialog()
                
            }catch(e){
                if(e.response.status === 422){
                    
                    let keys = Object.keys(e.response.data)
                    let values = Object.values(e.response.data)

                    this.errors = new Map();
                    for (let i = 0; i < keys.length; i++){
                        this.errors.set(keys[i], values[i]);
                    }
                    
                    if(this.errors.has("IRSZ")){
                        this.validIRSZ = false;
                    }
                    if(this.errors.has("TAJ")){
                        this.validTAJ = false;
                    }

                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: this.errors.entries().next().value[1], life: 3000})                   
                    
                }
                else{
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Valami hiba történt!", life: 3000})
                } 
            }
            this.submitted = false

        },
        checkErrors: function(){
            if(this.v$.beteg.postcode.$error){ 
                this.validIRSZ = true
            }
            if(this.v$.beteg.taj.$error){ 
                this.validTAJ = true
            }
        } 
    },
    computed: {
        isAddress2String: function() {
            return this.beteg.address2 !== null && this.beteg.address2 !== "" && isNaN(Number(this.beteg.address2))
        },
        isIRSZValid: function(){
            this.checkErrors()
            return this.validIRSZ
        },
        isTAJValid: function(){
            this.checkErrors()
            return this.validTAJ
        }
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        dialogData: function(v) {
            if(this.show){
                this.beteg = v
                this.validIRSZ = true;
                this.validTAJ = true;
            }
        }
    }
}
</script>

<style>

</style>