<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px'}" 
        header="Konzultációs díj módosítása" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="save()"
        @hide="hideDialog()" @keyup.esc="hideDialog()">

        <span class="p-field p-float-label">
            <Calendar id="date" ref="firstEditField" name="x-visit-datum" v-model="vizit.visitdate" dateFormat="yy.mm.dd" 
                      :showIcon="true" :showButtonBar="true" :showOnFocus="true"
                      :class="{'p-invalid': v$.vizit.visitdate.$invalid}" autocomplete="off"
            ></Calendar>
            <label for="date">Dátum</label>
            <small class="p-error" v-if="v$.vizit.visitdate.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <InputNumber v-model="vizit.visitcost" :maxFractionDigits="0" :min="0" suffix=" Ft"
                required="true" :class="{'p-invalid': v$.vizit.visitcost.$invalid}" autocomplete="off"
            />
            <label>Konzultációs díj</label>
            <small class="p-error" v-if="v$.vizit.visitcost.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Textarea id="description" v-model.lazy="vizit.visit_note" rows="8" cols="20" style="resize: vertical; color: rgba(0, 0, 0, 0.87)"
                 :v="v$.vizit.visit_note" @blur="v$.vizit.visit_note.$touch"
                :class="{'p-invalid': v$.vizit.visit_note.$error}"
            />
            <label for="description">Megjegyzés</label>
            <small class="p-error" v-if="v$.vizit.visit_note.$error && v$.vizit.visit_note.required.$invalid">{{ errorMessages.required }}</small>
        </span>

        <template #footer>
            <div>
                <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
                <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                    v-tooltip.bottom="'ESC'" tabindex="-1"
                    @click="hideDialog"/>
                <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                    v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                    @click="save"/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import VisitService from '@/service/backend/visit.service'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store'
import dayjs from 'dayjs'

export default {
    props: ['show','dialogData'],
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
    data() {

        const megjelenit = reactive({})
        const vizit = reactive({})

        return {
            User: store.getters['user/User'],

            megjelenit,
            errorMessages,
            submitted: false,

            vizit,

        }
    },
    validations() {

        return {
            vizit: {
                visitdate: required,
                visitcost: required,
                visit_note: required
            }
        }

    },

    visitService: null,

	created() {
        this.visitService = new VisitService()
        this.megjelenit = false
        this.vizit.visittype = {visitname: ""}
	},

    mounted() {
    },

    methods: {
        dialogShow() {
            this.vizit.saved = false
            this.submitted = false
            this.v$.$reset()
        },
        hideDialog() {
            this.megjelenit = false
            this.$emit('update:show', false)
        },
        async save() {
            this.v$.vizit.$touch()
            if (!this.v$.vizit.$invalid && !this.submitted) {
                this.submitted = true
                try {

                    let date = ""
                    if(typeof(this.vizit.visitdate) == "object"){
                        date = dayjs(this.vizit.visitdate).format('YYYY.MM.DD')
                        this.vizit.visitdate = date
                    }
                    
                    let new_visit = {
                        'id' :          this.vizit.visitid,
                        'userid' :      this.vizit.homecarer_id,
                        'customerid' :  this.vizit.customerid,
                        'visit_status': 'A',
                        'visittypeid' : this.vizit.visittypeid,
                        'visitcost' :   this.vizit.visitcost,
                        'evdate1' :     this.vizit.visitdate,
                        'note' :        this.vizit.visit_note
                    }

                    if(new_visit.visittypeid == 0 || !this.notEmpty(new_visit.visittypeid))
                        throw "new_visit.visittype üres!"

                    await this.visitService.updateVisit(new_visit)
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Vizit módosítva!', life: 3000})

                    this.vizit.saved = true
                    this.$emit('update:dialogData', this.vizit)

                    this.hideDialog()

                } catch (error) {
                    console.log(error)
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a rögzítésnél!', life: 3000})
                }
                this.submitted = false
            }
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined
        },
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        dialogData: function(v) {
            if(this.show){
                this.vizit = JSON.parse(JSON.stringify(v))
                this.vizit.visitcost = parseInt(v.visitcost)
            }
        },
    },
    computed: {
        isAdmin: function(){
            return this.User.role=='ADMIN';
        },
    }
}
</script>

<style scoped>

</style>