<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px', 'margin-top': '16px'}" 
        header="Lezárás" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="saveState()" 
        @hide="hideDialog()" @keyup.esc="hideDialog()">

        <span class="p-field p-float-label">
            <Calendar id="datefrom" ref="firstEditField" name="x-betegseg-status-datum" v-model="v$.allapot.date.$model" dateFormat="yy.mm.dd" 
                      :showIcon="true" :showButtonBar="true" :showOnFocus="true"
                      :class="{'p-invalid': v$.allapot.date.$invalid}" autocomplete="off"
            ></Calendar>
            <label for="datefrom">Dátum</label>
            <small class="p-error" v-if="v$.allapot.date.$error">{{ errorMessages.required }}</small>
        </span>

        <Message severity="info" v-if="!allapot.id">Ha lezárás előtt rögzítené a beteg státuszát,<br/> lépjen vissza, és adjon hozzá új státuszt.</Message>

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.allapot.result.$model" :options="resultOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="360px"
                required="true" :class="{'p-invalid': v$.allapot.result.$invalid}"
            />
            <label>Elért eredmények ápolás során</label>
            <small class="p-invalid" v-if="v$.allapot.result.$error">{{ errorMessages.required }}</small>
        </span>
        <!-- TODO edit esetén lehetne mellette egy gomb, hogy előző érték -->

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.allapot.reason.$model" :options="reasonOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="300px"
                required="true" :class="{'p-invalid': v$.allapot.reason.$invalid}"
            />
            <label>Otthontáplálás befejezésének oka</label>
            <small class="p-invalid" v-if="v$.allapot.reason.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Textarea v-model="allapot.note" rows="4" cols="20" style="resize: vertical"
            />
            <label>Megjegyzés</label>
        </span>

        <template #footer>
            <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
            <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                v-tooltip.bottom="'ESC'" tabindex="-1"
                @click="hideDialog"/>
            <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                @click="saveState"/>
        </template>
    </Dialog>
</template>

<script>
import PatientService from '@/service/backend/patient.service'
//import Vue from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import dayjs from 'dayjs'

export default {
    props: ['show','dialogData'],

    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },

    data() {
        const megjelenit = reactive({})
        const allapot = reactive({})
        
        return {
            megjelenit,
            submitted: false,
            errorMessages,

            allapot,

            resultOptions: [{id:'1',name:'Önellátás teljessé vált'},{id:'2',name:'Javult'},{id:'3',name:'Változatlan'},{id:'4',name:'Romlott'}],
            reasonOptions: [{id:'1',name:'Szájon át étkezik'},{id:'2',name:'Teljes önellátás-gondozás'},{id:'3',name:'Intézménybe került'},
                            {id:'4',name:'Nem kér további ápolást'},{id:'5',name:'Exitus'}],

        }
    },
    validations: {
        allapot: {
            homecarer: { },
            date: { required },
            result: { required },
            reason: { required },
        },
    },
	patientService: null,
	created() {
        this.patientService = new PatientService()
        this.megjelenit = false
	},
    mounted() {
        this.allapot.saved = false
    },
    methods: {
        dialogShow() {
            let a = JSON.parse(JSON.stringify(this.dialogData));
            if(a.id){
                a.date = new Date(a.date)
            }
            this.allapot = a
            this.v$.$reset()
            this.$refs.firstEditField.$el.focus()
        },
        hideDialog() {
            this.$emit('update:show', false)
        },
        saveState() {
            this.v$.allapot.$touch()
            if (!this.v$.allapot.$invalid) {
                this.save()
            }
        },
        async save() {

            this.submitted = true

            try{

                if(typeof(this.allapot.date) == "object"){
                    this.allapot.date = dayjs(this.allapot.date).format('YYYY.MM.DD')
                }
                this.allapot.evdate1 = this.allapot.date
                if (this.allapot.id) {
                    this.allapot.id = await this.patientService.updateStatus(this.allapot)
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                }
                else {
                    this.allapot.evtype = '9'
                    this.allapot.id = await this.patientService.newStatus(this.allapot)
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                }

                this.allapot.saved = true
                //this.allapot.updated = dayjs().format('YYYY.MM.DD HH:mm:ss')

                this.$emit('update:dialogData', this.allapot)

                this.hideDialog()
                
            }catch(e){
                // TODO server oldali hiba kiírása?
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }

            this.submitted = false

        }
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        }
    }
}
</script>
