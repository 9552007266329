<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px'}" 
        :header="headerTitle" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="save()"
        @hide="hideDialog()" @keyup.esc="hideDialog()">
        <div class="p-field" v-if="User.role=='PHARMACY'">
            <SelectButton v-model="v$.rendeles.status.$model"
                required="true" 
                :options="orderStatusOptions" optionLabel="value" optionValue="code"
            />
            <small class="p-error" v-if="v$.rendeles.evtype.$error">{{ errorMessages.required }}</small>
        </div>
        <div class="p-field">
            <SelectButton v-model="v$.rendeles.evtype.$model" :disabled="User.role=='PHARMACY'"
                required="true" :class="{'p-invalid': v$.rendeles.evtype.$invalid }" 
                :options="optionsTipus" optionLabel="value" optionValue="code"
            />
            <small class="p-error" v-if="v$.rendeles.evtype.$error">{{ errorMessages.required }}</small>
        </div>
        <span class="p-field p-float-label">
            <Dropdown id="orderitem" v-model="v$.rendeles.item.$model" :disabled="!rendeles.evtype || rendelhetosegek.length==0"
                :options="rendelhetosegek" optionLabel="name" placeholder="" :filter="true"
                required="true" :class="{'p-invalid': v$.rendeles.item.$invalid}"
            />
            <label for="orderitem">Rendelés</label>
            <small class="p-error" v-if="v$.rendeles.item.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 50%; display: inline-block">
            <InputNumber id="rendelesamount" v-model = "v$.rendeles.amount.$model"
                required="true" :class="{'p-invalid': v$.rendeles.amount.$invalid}" autocomplete="off" :suffix="amountType"
            />                
            <label for="rendelesamount" ref="amount-label">Mennyiség</label>
            <small class="p-error" v-if="v$.rendeles.amount.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 50%; display: inline-block" v-if="v$.rendeles.evtype.$model=='N'">
            <InputNumber id="rendelessuggestamount" v-model="rendeles.suggested_amount" suffix=" kg"
                required="true" :disabled="true" title="Számított..."
            />
            <label for="rendelessuggestamount" ref="amount-label">Javasolt mennyiség</label>
            <small v-if="v$.rendeles.amount.$error" style="color:white">.</small>
        </span>
        <span class="p-field p-float-label" v-if="isAdmin">
            <AutoComplete id="selected_homecarer" v-model="v$.rendeles.selected_homecarer.$model" 
                :suggestions="filteredUsers" @complete="searchUser($event)" field="name"
                :minLength="2" placeholder="Keresés 2 karaktertől..." autocomplete="off" 
                forceSelection required="true" :class="{'p-invalid': v$.rendeles.selected_homecarer.$invalid}" 
            />
            <label for="selected_homecarer" style="top: 1px; font-size: 12px;">Beteglátogató</label>
            <small class="p-error" v-if="v$.rendeles.selected_homecarer.$error">{{ errorMessages.required }}</small>
        <!-- TODO beteg listán is adminnak kiírni, hogy kinek a betege, hisz azokat listázzuk az ápolónak) -->
        </span>
        <span class="p-field p-float-label" style="margin-top: 1rem"> 
            <Dropdown v-model="v$.rendeles.pharmacy.$model" 
                :options="pharmacies" optionLabel="name" placeholder=""
                required="true" :class="{'p-invalid': v$.rendeles.pharmacy.$invalid}"
            />
            <label for="pharmacy">Patika</label>
            <small class="p-error" v-if="v$.rendeles.pharmacy.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label">
            <Textarea id="description" v-model="rendeles.note" rows="3" cols="20" style="resize: vertical"
            />
            <label for="description">Megjegyzés</label>
        </span>

        <template #footer>
            <div class="p-d-flex p-jc-between">
                <div class="p-as-end">
                    <Button :label="delButtonLabel" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-outlined p-button-warning" 
                        @click="deleteRendelesConfirm($event)" v-if="showDelButton"/>
                </div>
                <div>
                    <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
                    <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                        v-tooltip.bottom="'ESC'" tabindex="-1"
                        @click="hideDialog"/>
                    <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                        v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                        @click="save"/>
                </div>
            </div>
        </template>
        
    </Dialog>
</template>

<script>
import OrderService from '@/service/backend/order.service'
import UsersService from '@/service/backend/users.service'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store';
import dayjs from 'dayjs'

export default {
    props: ['show','dialogData','beteg'],
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
    data() {

        const megjelenit = reactive({})
        const rendeles = reactive({})

        return {
            User: store.getters['user/User'],

            megjelenit,
            errorMessages,
            submitted: false,

            rendeles,
            rendelhetosegek: [],
            pharmacies: [],
            filteredUsers: null,

            optionsTipus: [{'code': 'N', 'value' :'Tápszer'}, {'code': 'A', 'value' :'Eszköz'}],
            orderStatusOptions: [{'code':'2', 'value':'Feldolgozás alatt'}, {'code':'5', 'value':'Teljesítve'}, {'code':'8', 'value':'Elutasítva'}],

            nutritions: [],
            tools: [],

        }
    },
    validations: {
        rendeles: {
            id: { },
            evtype: { required },
            item: { required },
            amount: { required },
            selected_homecarer: { required: requiredIf(function () {
                        return this.isAdmin
                    }) }, 
            pharmacy: { required },
            status: { },
        }
    },

    orderService: null,
    usersService: null,

	created() {
        this.orderService = new OrderService()
		this.usersService = new UsersService()
        this.megjelenit = false
        this.loadNutriAndTools()
	},

    methods: {
        async dialogShow() {
            if(this.pharmacies.length == 0)
                this.loadPharmacies()
            this.rendeles.saved = false
            this.submitted = false

            if(!this.dialogData.id){

                this.rendelhetosegek = []

            }else{
                //this.fillRendelhetosegek(this.rendeles.evtype)
            }
            if(this.pharmacies.length == 1){
                this.rendeles.pharmacy = this.pharmacies[0] // TODO ez duplikálva, van watch is rá, melyik működik?
            }
            // }
            this.v$.$reset()
            //this.$refs.firstEditField.$el.focus()
        },
        hideDialog() {
            this.megjelenit = false
            this.$emit('update:show', false)
        },
        searchUser(event) {
            this.usersService.getUsersForSelect({filters: event.query })
            .then( result => {
                this.filteredUsers = result
            })
        },
        async loadPharmacies() {
            try{
                this.pharmacies = await this.usersService.getPharmaciesForSelect()
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a patikák betöltésénél', life: 3000})
            }
        },
        async loadNutriAndTools() {
            try{
                const nutri_and_tools = await this.orderService.getNutriAndToolsForSelect({'nutritype':'S'})
                this.nutritions = nutri_and_tools.nutritions
                this.tools = nutri_and_tools.tools
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a tápszerek betöltésénél', life: 3000})
            }
        },
        async save() {
            this.v$.rendeles.$touch()
            if (!this.v$.rendeles.$invalid && !this.submitted) {
                this.submitted = true
                try {

                    const kcal = parseInt(this.rendeles.item.kcal_kg) * Number(this.rendeles.amount)

                    if(this.isAdmin){
                        this.rendeles.homecarer_id = this.rendeles.selected_homecarer.id
                        this.rendeles.homecarer = this.rendeles.selected_homecarer.name
                    }

                    if(this.rendeles.id){

                        this.rendeles.id = await this.orderService.updateOrder({ 
                                                                    id: this.rendeles.id,
                                                                    evtype: this.rendeles.evtype,
                                                                    itemid: this.rendeles.item.id,
                                                                    itemname: this.rendeles.item.name,
                                                                    amount: this.rendeles.amount,
                                                                    kcal: kcal,
                                                                    userid: this.rendeles.homecarer_id, 
                                                                    pharmacyid: this.rendeles.pharmacy.id,
                                                                    note1: this.rendeles.note,
                                                                    ostatus: this.rendeles.status
                                                                })

                        if(this.rendeles.status == 5){
                            this.$toast.add({severity:'success', summary: 'Teljesítés mentve!', detail: 'Rendelés teljesítve!', life: 8000})
                        }else{
                            this.$toast.add({severity:'success', summary: 'Mentve!', detail: 'Rendelés módosítva!', life: 5000})
                        }

                    }else{

                        if(!this.isAdmin){
                            this.rendeles.homecarer_id = this.User.id 
                            this.rendeles.homecarer = this.User.name 
                        }

                        this.rendeles.id = await this.orderService.newOrder({ 
                                                                    customerid: this.beteg.id,
                                                                    evtype: this.rendeles.evtype,
                                                                    itemid: this.rendeles.item.id,
                                                                    amount: this.rendeles.amount,
                                                                    kcal_ordered: kcal,
                                                                    userid: this.rendeles.homecarer_id,
                                                                    pharmacyid: this.rendeles.pharmacy.id,
                                                                    evdate1: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                                                                    note1: this.rendeles.note,
                                                                })
                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Rendelés leadva!', life: 3000})
                    }
                    this.rendeles.saved = true
                    this.$emit('update:dialogData', this.rendeles)

                    this.hideDialog()

                } catch (error) {
                    console.log(error)
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a rendelésnél!', life: 3000})
                }
                this.submitted = false
            }
        },
        deleteRendelesConfirm(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztos benne?',
                header: this.delButtonLabel,
                acceptClass: 'p-button-warning',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.deleteRendeles()
                }
            });        
        },
        async deleteRendeles() {
            this.submitted = true
            try{
                if(this.rendeles.status == "1"){
                    await this.orderService.deleteOrder(this.rendeles.id)
                    this.rendeles.deleted = true
                }else{
                    await this.orderService.updateOrder({id: this.rendeles.id, ostatus: "9"})
                    this.rendeles.deletedLogically = true
                }
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Rendelés törölve!', life: 3000})
                this.$emit('update:dialogData', this.rendeles)
                this.hideDialog()
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a törlésnél!', life: 3000})
            }
            this.submitted = false
        },
        async fillRendelhetosegek(v) {
            if(this.nutritions.length == 0)
                await this.loadNutriAndTools()
            if(v == 'N'){
                this.rendelhetosegek = this.nutritions
            }else{
                this.rendelhetosegek = this.tools
            }
        },
        fillItem() {
            if(this.rendeles.evtype == 'N'){
                this.rendeles.item = this.nutritions.find(i => i.id == this.rendeles.item_id)
            }else{
                this.rendeles.item = this.tools.find(i => i.id == this.rendeles.item_id)
            }
        }
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        dialogData: function(v) {
            if(this.show){
                this.rendeles = JSON.parse(JSON.stringify(v));
                this.rendeles.pharmacy = {id: v.pharmacy_id, name: v.pharmacy}
                this.rendeles.selected_homecarer = v.homecarer_id ? {id: v.homecarer_id, name: v.homecarer} : null
                if(this.User.role=='ADMIN'){
                    this.rendeles.item_id = v.order_item_id // nutritions watchernél töltjük az item-et
                    this.rendeles.amount = eval(v.order_amount)
                    this.rendeles.note = v.manager_note
                }else
                if(this.User.role=='CARER'){
                    this.rendeles.item_id = v.order_item_id 
                    this.rendeles.amount = eval(v.order_amount)
                    this.rendeles.note = v.homecarer_note
                }
                if(this.User.role=='PHARMACY'){
                    if(v.status == "1") this.rendeles.status = "2"
                    this.rendeles.item_id = v.fulfil_item_id
                    this.rendeles.amount = eval(v.fulfil_amount)
                    this.rendeles.note = v.pharmacy_note
                }
                if(this.nutritions.length>0){
                    this.fillItem()
                }
            }
        },
        pharmacies: function(v) {
            if(v.length == 1){
                this.rendeles.pharmacy = v[0]
            }
        },
        nutritions: function() {
            if(this.rendeles.item_id){
                this.fillItem()
            }
        },
        "rendeles.evtype": function(v) {
            this.fillRendelhetosegek(v)
        },
        "rendeles.item.id": function() {
            if(this.rendeles.evtype == 'N' && this.rendeles.item != null && this.rendeles.item.kcal_kg != null){

                const num = ( parseInt(this.beteg.kcal_month) / parseInt(this.rendeles.item.kcal_kg) ) // szükséglet / kcal_kg -> kerekíteni
                if(!Number.isNaN(num))
                    this.rendeles.suggested_amount = Number(num.toFixed(2))
                else
                    this.rendeles.suggested_amount = null
            }else{
                this.rendeles.suggested_amount = null
            }
        },
    },
    computed: {
        isAdmin: function(){
            return this.User.role=='ADMIN';
        },
        headerTitle: function(){
            if(this.rendeles.id) 
                return "Rendelés módosítás"
            else
                return "Rendelés leadás"
        },
        showDelButton: function(){
            if(    ( this.User.role=='ADMIN' || this.User.role=='CARER' ) 
                && ( this.rendeles.status==1 || this.rendeles.status==2 )) 
            {
                return true
            }else
                return false

            // javaslat:
            //return ( this.User.role=='ADMIN' || this.User.role=='CARER' ) 
            //    && ( this.rendeles.status==1 || this.rendeles.status==2 )
            
        },
        delButtonLabel: function(){
            return ( this.rendeles.status==2 ) ? "Visszavon" : "Töröl"
        },
        amountType: function(){
            return ( this.rendeles.evtype === "N" ) ? " kg" : " db"
        }
    }
}
</script>

<style>

</style>