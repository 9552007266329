<template>
    <div class="p-grid dashboard" v-if="!loading">
		<div class="p-col-12 p-md-4 p-lg-3 details-panel">
            <Toast/>
            <div class="p-col-12">
                <Panel id="beteg-adat-panel" :header="beteg_name_val">
                    <Button v-if="isPatientEditable"
                            type="button" icon="pi pi-pencil" class="p-button-secondary panel-header-button-1" 
                            @click="toggleDialog(dataDialogTipus)"/>
                    <ul class="beteg-adat-container">
                        <li v-if="beteg.data.type=='P' && beteg.data.social_home!=null && beteg.data.social_home!=''">
                            <span v-tooltip.right="'Szociális otthon'">
                                <i class="pi pi-plus"></i>
                                <span class="beteg-adat" v-html="beteg.data.social_home_name"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'TAJ szám'">
                                <i class="pi pi-bars"></i>
                                <span class="beteg-adat" v-html="beteg.data.taj"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Születési dátum (kor)'">
                                <i class="pi pi-calendar"></i>
                                <span v-if="birthdateAndAge" class="beteg-adat" v-html="birthdateAndAge"/>
                                <span v-else class="beteg-adat p-error">Rögzítse a születési dátumot!</span>
                            </span>
                        </li>
                        <li>
                            <span v-tooltip.right="'Cím'">
                                <i class="pi pi-map-marker"></i>
                                <span class="beteg-adat" v-html="beteg.data.postcode + '. ' + beteg.data.city + ', ' + beteg.data.address1"/>
                            </span>
                        </li>
                        <li>
                            <span v-tooltip.right="'Telefon'">
                                <i class="pi pi-mobile"></i>
                                <span class="beteg-adat" v-html="beteg_tel_val"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Tápanyag-szükséglet - Utolsó állapot alapján'">
                                <i class="pi pi-star-o"></i>
                                <span v-if="KCAL" class="beteg-adat" v-html="KCAL"/> 
                                <span v-else class="beteg-adat p-error">Rögzítse a beteg állapot adatait!</span>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'BMI'">
                                <i class="pi pi-star"></i>
                                <span class="beteg-adat" v-html="BMI"/>
                            </span>
                        </li>
                        <li>
                            <span v-tooltip.right="'Megjegyzés'">
                                <i class="pi pi-list"></i>
                                <span class="beteg-adat" v-html="beteg.data.note"/>
                            </span>
                        </li>
                    </ul>
                    <ul class="beteg-adat-container" v-show="isOpenPanelA">
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Nem'">
                                <i class="pi pi-heart"></i>
                                <span class="beteg-adat" v-html="sexTipus[beteg.data.sex]"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Születési neve'">
                                <i class="pi pi-tag"></i>
                                <span class="beteg-adat" v-html="beteg.data.prevname"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Születési hely'">
                                <i class="pi pi-calendar-plus"></i>
                                <span class="beteg-adat" v-html="beteg.data.birthplace"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Anyja neve'">
                                <i class="pi pi-user"></i>
                                <span class="beteg-adat" v-html="beteg.data.mothersname"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Súly - Utolsó státusz alapján'">
                                <i class="pi pi-tablet"></i>
                                <span class="beteg-adat" v-html="beteg.data.weight"/> kg
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Magasság - Utolsó státusz alapján'">
                                <i class="pi pi-tablet"></i>
                                <span class="beteg-adat" v-html="beteg.data.height"/> cm
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span>
                                Hozzátartozó:
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Hozzátartozó neve'">
                                <i class="pi pi-list"></i>
                                <span class="beteg-adat" v-html="beteg.data.rel1"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Hozzátartozó címe'">
                                <i class="pi pi-list"></i>
                                <span class="beteg-adat" v-html="beteg.data.rel1addr1"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Hozzátartozó telefonszám'">
                                <i class="pi pi-list"></i>
                                <span class="beteg-adat" v-html="beteg.data.rel1tel1"/>
                            </span>
                        </li>
                        <li>
                            <span>
                                Rögzítés adatai:
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Eszköz behelyezés helye'">
                                <i class="pi pi-filter"></i>
                                <span class="beteg-adat" v-html="beteg_address2_val"/>
                            </span>
                        </li>
                        <li v-if="beteg.data.type=='P'">
                            <span v-tooltip.right="'Lejelentő kórház'">
                                <i class="pi pi-plus-circle"></i>
                                <span class="beteg-adat" v-html="beteg.data.rephospital_name"/>
                            </span>
                        </li>
                        <li>
                            <span v-tooltip.right="'Első beteglátogatás dátuma'">
                                <i class="pi pi-briefcase"></i>
                                <span class="beteg-adat" v-html="beteg.data.firstvisitdate"/>
                            </span>
                        </li>
                        <li>
                            <span v-tooltip.right="'Rögzítés dátuma'">
                                <i class="pi pi-pencil"></i>
                                <span class="beteg-adat" v-html="beteg.data.created"/>
                            </span>
                            <span v-tooltip.right="'Utolsó módosítás dátuma'" v-if="beteg.data.created!=beteg.data.updated">
                                - <span class="beteg-adat" v-html="beteg.data.updated"/>
                            </span>
                        </li>
                        <li>
                            <span>
                                Beleegyező nyilatkozat:
                            </span>
                        </li>
                        <li>
                            <span v-tooltip.right="'Beleegyező nyilatkozat'">
                                <span class="beteg-adat">
                                    <SplitButton icon="pi pi-search" :label="beteg.data.patientfile_name"
                                            @click="viewPatientFile(beteg.data.patientfile_id)" :model="fileSecondaryButtonPatient(beteg.data.patientfile_id)"
                                        />
                                </span>
                            </span>
                        </li>
                        <li>
                            <span v-tooltip.right="'A beteg beleegyezését adta'">
                                <i class="pi pi-check"></i>
                                <span class="beteg-adat" v-html="beteg.data.fmp_consent == 1 ? 'Igen' : beteg.data.fmp_consent == 0 ? 'Nem' : ''"/>
                            </span>
                        </li>
                    </ul>
                    <div class="details-panel-expander" @click="togglePanel('A')">
                        <i :class="expanderIconA"></i>
                    </div>
                </Panel>
            </div>
            <div class="p-col-12">
                <Panel header="Orvosok">
                    <Button v-if="isPatientEditable"
                        type="button" icon="pi pi-pencil" class="p-button-secondary panel-header-button-1" 
                        @click="toggleDialog('Doctor')"/>
                    <ul class="beteg-adat-container">
                        <li v-for="d in beteg.doctor" :key="d.id">
                            <span v-tooltip.right="doctorTipus[d.type]">
                                <i :class="['pi', doctorTipusIkon[d.type]]"></i>
                                <span class="beteg-adat" v-html="d.name"/>
                            </span>
                        </li>
                    </ul>
                </Panel>
            </div>
            <div class="p-col-12">
                <Panel header="Állapot">
                    <div class="panel-header-button-1">
                        <Button type="button" icon="pi pi-lock" class="p-button-warning" @click="toggleDialog('StatusClose')" v-tooltip.top="'Lezárás'" v-if="isPatientActive"/>
                        <Button type="button" icon="pi pi-plus" class="p-button-secondary" @click="toggleDialog('Status',newStatusAdd)"/>
                    </div>
                    <table>
                        <tbody>
                        <tr v-for="s in beteg.status" :key="s.id">
                            <td style="padding: 10px 0 10px 14px"><i :class="['pi', 'pi-briefcase']"></i></td>
                            <td>{{s.date}}</td>
                            <td>{{statusTipus[s.evtype]}}</td>
                            <td>
                                <Button v-if="isPatientEditable || s.evtype=='9'"
                                        :icon="(s.evtype==='2') ? 'pi pi-eye' : 'pi pi-pencil'" :title="(s.evtype==='2') ? 'Megtekint' : 'Szerkeszt'" class="p-button-secondary l-row-button" 
                                        @click="toggleDialog((s.evtype=='9')?'StatusClose':'Status',s)"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table v-show="isOpenPanelB">
                        <tbody>
                        <tr v-for="s in beteg.status_old" :key="s.id">
                            <td style="padding-left: 14px"><i :class="['pi', 'pi-briefcase']"></i></td>
                            <td>{{s.date}}</td>
                            <td>{{statusTipus[s.evtype]}}</td>
                            <td>
                                <Button v-if="isPatientEditable"
                                        :icon="(s.evtype==='2') ? 'pi pi-eye' : 'pi pi-pencil'" :title="(s.evtype==='2') ? 'Megtekint' : 'Szerkeszt'" class="p-button-secondary l-row-button" 
                                        @click="toggleDialog('Status',s)"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="details-panel-expander" @click="togglePanel('B')">
                        <i :class="expanderIconB" v-show="beteg.status_old.length>0"></i>
                    </div>
                </Panel>
            </div>
            <div class="p-col-12">
                <Panel header="Betegség">
                    <Button v-if="isPatientEditable"
                            type="button" icon="pi pi-plus" class="p-button-secondary panel-header-button-1" 
                            @click="toggleDialog('Illness')"/>
                    <table class="l-table-multiline">
                        <tbody>
                        <tr v-for="b in beteg.illness" :key="b.id">
                            <td style="padding: 10px 0 10px 14px" v-tooltip.right="b.illtype == '1' ? 'Fő betegség' : 'Kísérő betegség'">
                                <i :class="['pi', betegsegTipus[b.illtype]]"></i>
                            </td> <!-- TODO title -->
                            <td>{{b.bno}}</td>
                            <td>{{b.illname}}</td>
                            <td>
                                <Button v-if="isPatientEditable"
                                        icon="pi pi-pencil" title="Szerkeszt" class="p-button-secondary l-row-button" 
                                        @click="toggleDialog('Illness',b)"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                   <div class="details-panel-no-expander"/>
                </Panel>
            </div>
		</div>
		<div class="p-col-12 p-md-8 p-lg-9 details-panel">
            <div class="p-col-12">
                <Panel>
                        <template #header>
                            <span class="p-panel-title">Rendelések: eszközök, tápszerek</span>
                            <div style="margin-right: 0; margin-left: auto;">
                            <InlineMessage severity="error" v-show="!canOrder" :life="6000" :sticky="false" style="padding-top: 10px; padding-bottom: 10px;">
                                Hiányzik a beteg kalória-adata!
                            </InlineMessage>
                            <Button v-if="isPatientEditable"
                                    type="button" icon="pi pi-plus" label="Új rendelés" class="p-button-secondary" @click="toggleDialog('Order')" 
                                    style="margin-left: 10px;"/>
                            </div>
                        </template>
                        <div>
                        </div>
                    
                    <DataTable ref="orderdt" :value="beteg.order_list" v-model:expandedRows="expandedOrder" dataKey="id" :paginator="false" class="p-datatable-sm p-datatable-hoverable-rows"
                               @row-click="expandOrder">
                        <Column :expander="true" headerStyle="width: 3rem" />
                        <Column field="order_date" header="Megrendelve Teljesítve" headerStyle="width: 7rem" >
                            <template #body="slotProps">
                                <span v-html="order_dateShow(slotProps.data)"></span>
                            </template>
                        </Column>
                        <Column field="order_item_id" header="Rendelés" style="min-width: 20rem; max-width: 30rem; width: 20%;" >
                            <template #body="slotProps">
                                <span v-html="order_item_name(slotProps.data)"></span>
                            </template>
                        </Column>
                        <Column field="order_amount" header="Menny." headerStyle="width: 4.5rem" >
                            <template #body="slotProps">
                                <span v-html="order_amountShow(slotProps.data)"></span>
                            </template>
                        </Column>
                        <Column field="homecarer" header="Ápoló" ></Column>
                        <Column field="pharmacy" header="Patika" ></Column>
                        <Column field="status" header="Állapot" >
                            <template #body="slotProps">
                                <Badge :value="orderStatus[slotProps.data.status]" :class="'status-'+slotProps.data.status"></Badge>
                            </template>
                        </Column>
                        <Column field="homecarer_note" header="Megjegyzés" headerStyle="width: 8rem" >
                            <template #body="slotProps">
                                <tag v-if="notEmpty(slotProps.data.homecarer_note)" value="Ápoló"/>
                                <tag v-if="notEmpty(slotProps.data.pharmacy_note)" value="Patika" severity="warning"/>
                                <tag v-if="notEmpty(slotProps.data.manager_note)" value="Manager" severity="danger"/>                            
                            </template>
                        </Column>
                        <Column headerStyle="width: 5rem">
                            <template #body="slotProps">
                                <div>
                                    <Button icon="pi pi-pencil" title="Szerkeszt" class="p-button-secondary l-row-button" v-show="orderEditable(slotProps.data.status)" style="display: inline-block"
                                        @click="toggleDialog('Order',slotProps.data)"/>
                                </div>
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <Card>
                            <template #content>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div v-if="slotProps.data.visitid">
                                            <span style="font-weight: bold; margin: 0px 3.1rem 0px 2rem">Konzultációs díj:</span>
                                            <p class="p-d-inline">{{ slotProps.data.visitcost.toLocaleString() }} Ft</p><br/>
                                            <span v-if="notEmpty(slotProps.data.visitdate) && slotProps.data.fulfil_date != slotProps.data.visitdate">
                                                <span style="font-weight: bold; margin: 0px 1.5rem 0px 2rem">Elszámolás dátuma:</span>
                                                <p class="p-d-inline">{{ slotProps.data.visitdate }}</p><br/>
                                            </span>
                                            <span style="font-weight: bold; margin: 0px 1.5rem 0px 2rem">Vizit megjegyzés:</span>
                                            <p style="margin: 0px 0.5rem 0px 2rem; white-space: pre-wrap" v-html="slotProps.data.visit_note"></p>
                                            <Button type="button" icon="pi pi-pencil" label="Módosít" class="p-button-primary l-btn-order-cons" @click="toggleDialog('OrderConsult', slotProps.data)" v-if="isAdmin"/> 
                                        </div>
                                    </div>
                                    <div class="p-col-7 p-grid">
                                        <div class="p-col-2" v-if="notEmpty(slotProps.data.homecarer_note)">
                                            <tag value="Ápoló"/>
                                        </div>
                                        <div class="p-col-10" v-if="notEmpty(slotProps.data.homecarer_note)">
                                            <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.homecarer_note"></p>
                                        </div>
                                        <div class="p-col-2" v-if="notEmpty(slotProps.data.pharmacy_note)">
                                            <tag value="Patika" severity="warning"/>
                                        </div>
                                        <div class="p-col-10" v-if="notEmpty(slotProps.data.pharmacy_note)">
                                            <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.pharmacy_note"></p>
                                        </div>
                                        <div class="p-col-2" v-if="notEmpty(slotProps.data.manager_note)">
                                            <tag value="Manager" severity="danger"/>
                                        </div>
                                        <div class="p-col-10" v-if="notEmpty(slotProps.data.manager_note)">
                                            <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.manager_note"></p>
                                        </div>
                                    </div>
                                </div>
                            </template>                            
                            </Card>
                        </template>
                    </DataTable>
                    <div class="details-panel-expander" @click="togglePanel('E')">
                        <i :class="expanderIconE" v-show="beteg.order.length>beteg.order_list.length || isOpenPanelE"></i>
                    </div>

                </Panel>

            </div>
            <div class="p-col-12">
                <Panel header="Vizitek">
                    <div class="panel-header-button-1" v-if="isPatientEditable">
                        <div v-tooltip.top="visit12Tooltip" style="display:inline-block">
                            <Button type="button" icon="pi pi-plus" label="Vizit" class="p-button-secondary" @click="toggleDialog('Visit')" :disabled="!isVisibleVisit12"/>
                        </div>
                        <div v-tooltip.top="visitAdvisoryTooltip" style="display:inline-block">
                            <Button type="button" icon="pi pi-plus" label="Tanácsadás" class="p-button-secondary" @click="toggleDialog('Advisory')" :disabled="!isVisibleVisitAdvisory"/> 
                        </div>
                        <Button type="button" icon="pi pi-plus" label="Protokollon kívüli vizit" class="p-button-secondary" @click="toggleDialog('ExVisitRequest')"/>
                    </div>
                    <DataTable ref="visitdt" :value="beteg.visit_list" v-model:expandedRows="expandedVisit" dataKey="id" :paginator="false" class="p-datatable-sm p-datatable-hoverable-rows"
                               @row-click="expandVisit">
                        <Column :expander="true" headerStyle="width: 3rem" />
                        <Column field="date" header="Dátum" headerStyle="width: 7rem" ></Column>
                        <Column field="type" header="Típus" style="min-width: 20rem; max-width: 30rem; width: 30%;" ></Column>
                        <Column field="homecarer" header="Ápoló" style="min-width: 6.5rem; max-width: 10rem; width: 16%;" ></Column>
                        <Column header="Vizitlap" headerStyle="width: 6.5rem">
                            <template #body="slotProps">
                                <Badge value="Feltöltve" class="visit-status-T" v-if="slotProps.data.visitfile_id"></Badge>
                            </template>
                        </Column>
                        <Column header="Állapot" headerStyle="width: 6.5rem" >
                            <template #body="slotProps">
                                <Badge :value="visitTipus[slotProps.data.status]" :class="'visit-status-'+slotProps.data.status"></Badge>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" title="Szerkeszt" class="p-button-secondary l-row-button" v-show="visitEditable(slotProps.data)"
                                    @click="toggleDialog('Visit',slotProps.data)"/>
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <Card>
                            <template #content>
                                <div class="p-grid">
                                    <div class="p-col-4 p-grid">
                                        <div class="p-col-2" v-if="notEmpty(slotProps.data.req_reason)">
                                            <span style="font-weight: bold">Típus:</span>
                                        </div>
                                        <div class="p-col-10" v-if="notEmpty(slotProps.data.req_reason)">
                                            <p class="p-d-inline" v-html="reqReasonTipus[slotProps.data.req_reason]"></p>
                                        </div>
                                        <div class="p-col-2">
                                            <span style="font-weight: bold">Díj:</span>
                                        </div>
                                        <div class="p-col-10">
                                            <p class="p-d-inline">{{ slotProps.data.visitcost.toLocaleString() }} Ft</p>
                                        </div>
                                        <div class="p-col-2" v-if="slotProps.data.visitfile_id">
                                            <span style="font-weight: bold">Vizitlap:</span>
                                        </div>
                                        <div class="p-col-12" v-if="slotProps.data.visitfile_id">
                                            <SplitButton icon="pi pi-search" :label="slotProps.data.visitfile_name"
                                                @click="viewVisitFile(slotProps.data.visitfile_id)" :model="fileSecondaryButton(slotProps.data.visitfile_id)"
                                            />
                                        </div>
                                    </div>
                                    <div class="p-col-8 p-grid">
                                        <div class="p-col-2" v-if="notEmpty(slotProps.data.req_note)">
                                            <span style="font-weight: bold">Kérelem indoklása:</span>
                                        </div>
                                        <div class="p-col-10" v-if="notEmpty(slotProps.data.req_note)">
                                            <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.req_note"></p>
                                        </div>
                                        <div class="p-col-2" v-if="notEmpty(slotProps.data.note)">
                                            <span style="font-weight: bold">Megjegyzés:</span>
                                        </div>
                                        <div class="p-col-10" v-if="notEmpty(slotProps.data.note)">
                                            <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                        </div>
                                    </div>
                                </div>
                            </template>                            
                            </Card>
                        </template>
                    </DataTable>
                    <div class="details-panel-expander" @click="togglePanel('D')">
                        <i :class="expanderIconD" v-show="beteg.visit.length>beteg.visit_list.length || isOpenPanelD"></i>
                    </div>
                </Panel>
            </div>
        </div>

        <PatientDialog v-model:show="isOpenPatientDialog" v-model:dialogData="dialogData"/>
        <SocHomeDialog v-model:show="isOpenSocHomeDialog" v-model:dialogData="dialogData"/>
        <DoctorDialog  v-model:show="isOpenDoctorDialog"  v-model:dialogData="dialogData"/>
        <StatusDialog  v-model:show="isOpenStatusDialog"  v-model:dialogData="dialogData" :birthdate="beteg.data.birthdate"/>
        <StatusCloseDialog v-model:show="isOpenStatusCloseDialog" v-model:dialogData="dialogData"/>
        <IllnessDialog v-model:show="isOpenIllnessDialog" v-model:dialogData="dialogData"/>
        <OrderDialog   v-model:show="isOpenOrderDialog"   v-model:dialogData="dialogData" :beteg="beteg.data"/>
        <VisitDialog   v-model:show="isOpenVisitDialog"   v-model:dialogData="dialogData"/>
        <OrderConsultDialog v-model:show="isOpenOrderConsultDialog" v-model:dialogData="dialogData"/>
        <FileViewer v-model:show="isOpenFileViewer" v-model:fileName="viewFileName" v-model:fileData="viewFileData"/>
        

	</div>

    <!-----------------------------------------------------------------Skeleton------------------------------------------------------------------->

    <div class="p-grid dashboard" v-if="loading">
        <template  v-if="loading">
            <div class="p-col-12 p-md-4 p-lg-3 details-panel">
                <Toast/>
                <div class="p-col-12">
                    <Panel id="beteg-adat-panel" :header="beteg_name_val">
                        <ul class="beteg-adat-container">
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                        </ul>                    
                        <div class="details-panel-expander" @click="togglePanel('A')">
                            <i :class="expanderIconA"></i>
                        </div>
                    </Panel>
                </div>
                <div class="p-col-12">
                    <Panel header="Orvosok">
                        <ul class="beteg-adat-container">
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                            <li>
                                <Skeleton height="1.5rem"></Skeleton>
                            </li>
                        </ul>
                    </Panel>
                </div>
                <div class="p-col-12">
                    <Panel header="Állapot">
                        <table class="l-table-multiline">
                            <tbody>
                            <tr>
                                <td><Skeleton size="1.5rem" class="p-mr-2"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="8rem"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="5rem"></Skeleton></td>
                            </tr>
                            <tr>
                                <td><Skeleton size="1.5rem" class="p-mr-2"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="8rem"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="5rem"></Skeleton></td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="details-panel-expander" @click="togglePanel('B')">
                            <i :class="expanderIconB" v-show="beteg.status_old.length>0"></i>
                        </div>
                    </Panel>
                </div>
                <div class="p-col-12">
                    <Panel header="Betegség">
                        <table class="l-table-multiline">
                            <tbody>
                            <tr>
                                <td><Skeleton shape="circle" size="1.5rem"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="5rem"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="8rem"></Skeleton></td>
                            </tr>
                            <tr>
                                <td><Skeleton shape="circle" size="1.5rem"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="5rem"></Skeleton></td>
                                <td><Skeleton height="1.5rem" width="8rem"></Skeleton></td>
                            </tr>
                            </tbody>
                        </table>
                    <div class="details-panel-no-expander"/>
                    </Panel>
                </div>
            </div>
            <div class="p-col-12 p-md-8 p-lg-9 details-panel">
                <div class="p-col-12">
                    <Panel>
                            <template #header>
                                <span class="p-panel-title">Rendelések: eszközök, tápszerek</span>
                                <div style="margin-right: 0; margin-left: auto;">
                                    <Skeleton height="2.7rem" width="8.5rem" class="p-mb-2"></Skeleton>
                                </div>
                            </template> 
                            <DataTable :value="skeletons" class="p-datatable-sm p-datatable-hoverable-rows">
                                <Column :expander="true" headerStyle="width: 3rem" />
                                <Column field="order_date" header="Megrendelve Teljesítve" headerStyle="width: 7rem">
                                    <template #body>
                                        <Skeleton height="2rem"></Skeleton>
                                    </template>
                                </Column>
                                <Column field="order_item_id" header="Rendelés" style="min-width: 20rem; max-width: 30rem; width: 20%;">
                                    <template #body>
                                        <Skeleton height="2rem"></Skeleton>
                                    </template>
                                </Column>
                                <Column field="order_amount" header="Menny." headerStyle="width: 4.5rem">
                                    <template #body>
                                        <Skeleton height="2rem"></Skeleton>
                                    </template>
                                </Column>
                                <Column field="homecarer" header="Ápoló" >
                                    <template #body>
                                        <Skeleton height="2rem"></Skeleton>
                                    </template>
                                </Column>
                                <Column field="pharmacy" header="Patika" >
                                    <template #body>
                                        <Skeleton height="2rem"></Skeleton>
                                    </template>
                                </Column>
                                <Column field="status" header="Állapot">
                                    <template #body>
                                        <Skeleton height="2rem"></Skeleton>
                                    </template>
                                </Column>
                                <Column field="homecarer_note" header="Megjegyzés" headerStyle="width: 8rem">
                                    <template #body>
                                        <Skeleton height="2rem"></Skeleton>
                                    </template>
                                </Column>
                                <Column headerStyle="width: 5rem"></Column>
                            </DataTable>
                        <div class="details-panel-expander" @click="togglePanel('E')">
                            <i :class="expanderIconE"></i>
                        </div>
                    </Panel>
                </div>
                <div class="p-col-12">
                    <Panel header="Vizitek">
                        <div class="panel-header-button-1" v-if="isPatientEditable">
                            <div v-tooltip.top="visit12Tooltip" style="display:inline-block">
                                <Skeleton height="2.7rem" width="8.5rem" class="p-mb-2"></Skeleton>
                            </div>
                            <div v-tooltip.top="visitAdvisoryTooltip" style="display:inline-block">
                                <Skeleton height="2.7rem" width="8.5rem" class="p-mb-2"></Skeleton> 
                            </div>
                            <div style="display:inline-block">
                                <Skeleton height="2.7rem" width="8.5rem" class="p-mb-2"></Skeleton>
                            </div>
                        </div>
                        <DataTable ref="visitdt" :value="skeletons" v-model:expandedRows="expandedVisit" dataKey="id" :paginator="false" class="p-datatable-sm p-datatable-hoverable-rows"
                                @row-click="expandVisit">
                            <Column :expander="true" headerStyle="width: 3rem" />
                            <Column field="date" header="Dátum" headerStyle="width: 7rem" >
                                <template #body>
                                    <Skeleton height="2rem"></Skeleton>
                                </template> 
                            </Column>
                            <Column field="type" header="Típus" style="min-width: 20rem; max-width: 30rem; width: 30%;" >
                                <template #body>
                                    <Skeleton height="2rem"></Skeleton>
                                </template> 
                            </Column>
                            <Column field="homecarer" header="Ápoló" style="min-width: 6.5rem; max-width: 10rem; width: 16%;" >
                                <template #body>
                                    <Skeleton height="2rem"></Skeleton>
                                </template> 
                            </Column>
                            <Column field="homecarer" header="Állapot" headerStyle="width: 6.5rem" >
                                <template #body>
                                    <Skeleton height="2rem"></Skeleton>
                                </template> 
                            </Column>
                            <Column></Column>
                        </DataTable>
                        <div class="details-panel-expander" @click="togglePanel('D')">
                            <i :class="expanderIconD"></i>
                        </div>
                    </Panel>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
//import Vue from 'vue'
import PatientService from '@/service/backend/patient.service'

import PatientDialog from '@/components/patient/PatientDialog'
import SocHomeDialog from '@/components/patient/SocHomeDialog'
import DoctorDialog from '@/components/patient/DoctorDialog'
import StatusDialog from '@/components/patient/StatusDialog'
import StatusCloseDialog from '@/components/patient/StatusCloseDialog'
import IllnessDialog from '@/components/patient/IllnessDialog'
import VisitDialog from '@/components/patient/VisitDialog'
import OrderDialog from '@/components/patient/OrderDialog'
import OrderConsultDialog from '@/components/patient/OrderConsultDialog'
import FileViewer from '@/components/patient/FileViewer'
import { reactive } from 'vue'
import { store } from '@/store';
import dayjs from 'dayjs'

export default {
    components: {
        PatientDialog, SocHomeDialog, DoctorDialog, StatusDialog, StatusCloseDialog, IllnessDialog, VisitDialog, OrderDialog, OrderConsultDialog, FileViewer
    },
	data() {

        function initExpanderIcon() {
            return ['pi', 'pi-angle-down']
        }

        const beteg = reactive({
            data: { title: '', name: '...', taj: '...', birthdate: '',
                    postcode: '', city: '', address1: '',
                    weight: '...', kcal_day: '...', kcal_month: '...'}, 
            doctor: {},
            status: [{evtype: 0}],
            status_old: [],
            x_visittypes: [],
            visit: [],
        })
        const dialogData = reactive({})
        const viewFileData = reactive({})
        const viewFileName = reactive({})

		return {
            skeletons: new Array(4),

            User: store.getters['user/User'],

            loading: true,
            canOrder: true,

            isOpenPanelA: false,
            expanderIconA: initExpanderIcon(), 
            isOpenPanelB: false,
            expanderIconB: initExpanderIcon(), 
            isOpenPanelC: false,
            expanderIconC: initExpanderIcon(), 
            isOpenPanelD: false,
            expanderIconD: initExpanderIcon(), 
            isOpenPanelE: false,
            expanderIconE: initExpanderIcon(), 

            isOpenPatientDialog: false,
            isOpenSocHomeDialog: false,
            isOpenDoctorDialog: false,
            isOpenStatusDialog: false,
            isOpenStatusCloseDialog: false,
            isOpenIllnessDialog: false,
            isOpenVisitDialog: false,
            isOpenOrderDialog: false,
            isOpenOrderConsultDialog: false, 
            isOpenFileViewer: false,

            sexTipus: {'M':'férfi', 'F':'nő'},
            betegsegTipus: {'1':'pi-circle-on', '2':'pi-circle-off'},
            doctorTipus: {'E':'Eszköz-behelyező orvos', 'K':'Kezelő orvos', 'T':'Értesítő orvos'},
            doctorTipusIkon: {'E':'pi-filter', 'K':'pi-user-plus', 'T':'pi-directions'},
            statusTipus: {'0':'Rögzítés', '1':'Felvitel', '2':'Felvétel', '5':'Köztes', '9':'Lezárás'},
            visitTipus: {'T':'Teljesítve', 'A':'Automata', 'J':'Jóváhagyva', 'K':'Kérelmezve', 'X':'Elutasítva'},
            orderStatus: {'1':'Megrendelt', '2': 'Feldolgozás alatt', '5':'Teljesítve', '6':'Lezárt', '8':'Elutasítva', '9':'Visszavont'},
            reqReasonTipus: {'1':'Nasogastricus szonda csere', 
                               '2':'Gastrotubus csere',
                               '3':'Button csere',
                               '4':'PEG alkatrész csere',
                               '5':'Beteg orvosa kéri a vizitet',
                               '9':'egyéb'},

            beteg,
            dialogData,
            viewFileData,
            viewFileName,
            expandedOrder: [],
            expandedVisit: [],

            today: null,
            
		}
	},
	patientService: null,
	created() {
        this.today = dayjs() // Teszt miatt, lehessen más dátumot is megadni, nézni a vizitek viselkedését

        this.loading = true;
        this.patientService = new PatientService();
    
    },
	mounted() {
        const id = this.$route.params.patientId

        this.patientService.getPatientDetails(id)
        .then( result => {
            this.beteg = result
            this.beteg.order_list = this.beteg.order.slice(0,4)
            this.beteg.visit_list = this.beteg.visit.slice(0,4)
            this.loading = false

            if(this.$route.query.new || (this.beteg.status.length > 0 && this.beteg.status[0].evtype != '2' && !this.beteg.status[0].kcal_day && this.beteg.status[0].activity)){
                if(this.$route.query.new){
                    setTimeout(() => { this.toggleDialog('Status') }, 300)
                }else{
                    this.beteg.status[0].setKcal = true
                    setTimeout(() => { this.toggleDialog('Status', this.beteg.status[0]) }, 300)
                }                
            }
        })
	},
	methods: {
		toggle(event) {
            this.$refs.menu.toggle(event);
		},
        togglePanel(p) {
            this['expanderIcon' + p].pop()
            if(this['isOpenPanel' + p]){
                this['expanderIcon' + p].push('pi-angle-down')
            }else{
                this['expanderIcon' + p].push('pi-angle-up')
            }

            this['isOpenPanel' + p] = !this['isOpenPanel' + p]

            if(p=='E'){
                this.beteg.order_list = (this.isOpenPanelE) ? this.beteg.order
                                                            : this.beteg.order.slice(0,4)
            }else
            if(p=='D'){
                this.beteg.visit_list = (this.isOpenPanelD) ? this.beteg.visit
                                                            : this.beteg.visit.slice(0,4)
            }
        },
        toggleDialog(type, edit = false) {

            if( this.beteg.data.type != "S" &&
                type == 'Order' &&
                (this.beteg.data.kcal_day == '' || this.beteg.data.kcal_day == null))
            {
                this.canOrder = false
                return
            }else
                this.canOrder = true

            switch(type){
                case "Patient":
                case "SocHome":
                    this.dialogData = JSON.parse(JSON.stringify(this.beteg.data))
                    if(this.dialogData.social_home !== "" && this.dialogData.social_home !== null){
                        this.dialogData.in_social_home = true
                        this.dialogData.social_home_selected = {'code': this.dialogData.social_home ?? "", 'name': this.dialogData.social_home_name ?? ""}
                    }
                    if(type=="Patient"){
                        this.dialogData.address2_selected = {'code': this.dialogData.address2 ?? "", 'name': this.dialogData.address2_name ?? ""}
                        this.dialogData.rephospital_selected = {'code': this.dialogData.rephospital ?? "", 'name': this.dialogData.rephospital_name ?? ""}
                    }
                    break;
                case "Doctor":
                    this.dialogData = {}
                    this.dialogData.doctor = JSON.parse(JSON.stringify(this.beteg.doctor))
                    break;
                case "Status":
                    if(edit) this.dialogData = edit
                    else     this.dialogData = { cond: this.beteg.status[0].cond,
                                                 feedtype: this.beteg.status[0].feedtype,
                                                 sonda: this.beteg.status[0].sonda,
                                                 sondatype: this.beteg.status[0].sondatype,
                                                 activity: (this.beteg.status[0].activity=="J" || this.beteg.status[0].activity=="F") ? this.beteg.status[0].activity : null, 
                                                 homecarer_id: this.beteg.status[0].homecarer_id,
                                                 homecarer: this.beteg.status[0].homecarer,
                                                 note: "" }
                    break;
                case "Order":
                    if(edit) this.dialogData = edit
                    else {
                        this.dialogData = { note: "" }
                        if(this.isAdmin && this.beteg.order_list.length > 0){
                            this.dialogData.homecarer_id = this.beteg.order_list[0].homecarer_id
                            this.dialogData.homecarer    = this.beteg.order_list[0].homecarer
                            this.dialogData.pharmacy_id  = this.beteg.order_list[0].pharmacy_id
                            this.dialogData.pharmacy     = this.beteg.order_list[0].pharmacy
                        }
                    }
                    break;
                case "Visit":
                case "Advisory":
                case "ExVisitRequest":
                    if(edit) this.dialogData = edit
                    else {
                        this.dialogData = { note: "", req_note: "" }
                        if(this.isAdmin && this.beteg.visit_list.length > 0){
                            this.dialogData.userid    = this.beteg.visit_list[0].userid
                            this.dialogData.homecarer = this.beteg.visit_list[0].homecarer
                        }
                    }
                    this.dialogData.firstvisitdate = (this.notEmpty(this.beteg.data.firstvisitdate)) ? dayjs(this.beteg.data.firstvisitdate,'YYYY.MM.DD') : this.today
                    break;
                case "OrderConsult":
                    this.dialogData = edit
                    break;
                default:  //  ?
                    if(edit) this.dialogData = edit
                    else     this.dialogData = { note: "" }
            }
            this.dialogData.customerid = this.beteg.data.id

            switch(type){
                case "Visit":
                    if(!edit){
                        if(this.isFirstVisitMonth)
                            this.dialogData.visittype = this.beteg.x_visittypes.find(v => v.category == 'VIZIT_IDOSZAK_1')
                        else
                            this.dialogData.visittype = this.beteg.x_visittypes.find(v => v.category == 'VIZIT_IDOSZAK_2')
                    }else
                        this.dialogData.visittype = this.beteg.x_visittypes.find(v => v.id == this.dialogData.visittypeid)
                    this.dialogData.visittype1 = this.beteg.x_visittypes.find(v => v.category == 'VIZIT_IDOSZAK_1')
                    this.dialogData.visittype2 = this.beteg.x_visittypes.find(v => v.category == 'VIZIT_IDOSZAK_2')
                    break;
                case "Advisory":
                    this.dialogData.visittype = this.beteg.x_visittypes.find(v => v.category == 'VIZIT_TAVOLI')
                    type = "Visit"
                    break;
                case "ExVisitRequest":
                    this.dialogData.visittype = this.beteg.x_visittypes.find(v => v.category == 'VIZIT_PROTKIVUL')
                    type = "Visit"
                    break;
            }

            this['isOpen'+type+'Dialog'] = true;

        },
        order_dateShow(order) {
            let date = "<span style='font-weight: 700'>" + order.order_date + "</span>"
            if(order.fulfil_date != "" && order.fulfil_date != null && order.fulfil_date)
                date += "<br/>" + order.fulfil_date
            return date
        },
        order_item_name(order) {
            if(order.order_item_id == order.fulfil_item_id){
                return order.order_item_name
            }else{
                return "<span style='text-decoration: line-through;'>" + order.order_item_name + "</span><br/>" + order.fulfil_item_name
            }
        },
        order_amountShow(order) {
            let amountType = "db"
            if(order.evtype === "N")    
                amountType = "kg"

            if(order.order_amount == order.fulfil_amount){
                return order.order_amount + " " + amountType
            }else{
                return "<span style='text-decoration: line-through;'>" + order.order_amount 
                + " " + amountType +"</span><br/>" + order.fulfil_amount + " " + amountType
            }
        },
        expandOrder(e) {
            if(this.expandedOrder.length === 0 || this.expandedOrder[0].id !== e.data.id ){
                this.expandedOrder = this.beteg.order_list.filter(p => p.id == e.data.id);
            }else{
                this.expandedOrder = []
            }
        },
        expandVisit(e) {
            if(this.expandedVisit.length === 0 || this.expandedVisit[0].id !== e.data.id ){
                this.expandedVisit = this.beteg.visit_list.filter(p => p.id == e.data.id);
            }else{
                this.expandedVisit = []
            }
        },
        calcAge: function(bd) {
            // TODO mixin? -be tenni
            var today = dayjs();
            var birthDate = dayjs(bd);
            var age = today.year() - birthDate.year();
            var m = today.month() - birthDate.month();
            if (m < 0 || (m === 0 && today.date() < birthDate.date())) 
            {
                age--;
            }
            return age;

        },
        visitEditable(visit) {
            return this.isPatientEditable && (this.beteg.x_visittypes.find(v => v.id == visit.visittypeid) && visit.status != 'X')
        },
        orderEditable(orderStatus) {
            return this.isPatientEditable && (orderStatus == 1 || orderStatus == 2)
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined
        },
        async viewVisitFile(fileId) {

            try{
                const downloadedFile = await this.patientService.downloadVisitFile(fileId)

                this.viewFileName = Buffer.from(downloadedFile.headers['x-filename'], 'base64')

                const blo = new Blob([downloadedFile.data])
                this.viewFileData = window.URL.createObjectURL(blo)

                this.isOpenFileViewer = true;

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },
        async downloadVisitFile(fileId) {

            try{
                const downloadedFile = await this.patientService.downloadVisitFile(fileId)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Letöltve!', life: 3000})

                const fileName = Buffer.from(downloadedFile.headers['x-filename'], 'base64')

                const blo = new Blob([downloadedFile.data])
                const url = window.URL.createObjectURL(blo)
                const link = document.createElement('a')

                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },
        fileSecondaryButton(param) {
            return [{
					label: 'Letölt',
					icon: 'pi pi-download',
					command: () => {
						this.downloadVisitFile(param)
					}
            }]

        },
        async viewPatientFile(fileId) {

            try{
                const downloadedFile = await this.patientService.downloadPatientFile(fileId)

                this.viewFileName = Buffer.from(downloadedFile.headers['x-filename'], 'base64')

                const blo = new Blob([downloadedFile.data])
                this.viewFileData = window.URL.createObjectURL(blo)

                this.isOpenFileViewer = true;

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },
        async downloadPatientFile(fileId) {

            try{
                const downloadedFile = await this.patientService.downloadPatientFile(fileId)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Letöltve!', life: 3000})

                const fileName = Buffer.from(downloadedFile.headers['x-filename'], 'base64')

                const blo = new Blob([downloadedFile.data])
                const url = window.URL.createObjectURL(blo)
                const link = document.createElement('a')

                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },
        fileSecondaryButtonPatient(param) {
            return [{
					label: 'Letölt',
					icon: 'pi pi-download',
					command: () => {
						this.downloadPatientFile(param)
					}
            }]

        },
	},
    computed: {
        isAdmin: function() {
            return this.User.role == 'ADMIN'
        },
        isPatientActive: function() {
            return (!this.beteg.status.status) || (this.beteg.status[0].evtype != '9' && this.beteg.nstatus != 'L')
        },
        isPatientEditable: function() {
            return this.isPatientActive || this.isAdmin // Ha aktív vagy ha admin, akkor szerkeszthető TODO Anitával megbeszélni, most legyen szerkeszthető mindig
        },
        newStatusAdd: function() {
            if((this.beteg.status.length === 1 && this.beteg.status[0].evtype === '2')
               || (this.beteg.status.length > 1 || (this.beteg.status[0].kcal_day && this.beteg.status[0].feedtype)))
                return null
            else
                return this.beteg.status[0]
        },
        beteg_name_val: function() {
            return this.beteg.data.title + ' ' + this.beteg.data.name
        },
        beteg_address2_val: function() {
            return isNaN(Number(this.beteg.data.address2)) ? this.beteg.data.address2 : this.beteg.data.address2_name
        },
        beteg_tel_val: function() {
            let t2 = (this.beteg.data.tel1 != "" && this.beteg.data.tel2 != "" && this.beteg.data.tel2 != null) ? ", " : ""
            t2+= this.beteg.data.tel2 ?? ""
            const t= this.beteg.data.tel1 + t2
            return t==='null' ? '' : t
        },
        dataDialogTipus: function() {
            return (this.beteg.data.type=='P') ? 'Patient' : 'SocHome'
        },
        birthdateAndAge: function() {
            //return this.beteg.data.birthdate
            if(this.beteg.data.birthdate != "" && this.beteg.data.birthdate != null) {
                const bd = this.beteg.data.birthdate.replaceAll(".", "-")
                if(dayjs(bd).isValid()) 
                    return this.beteg.data.birthdate + " (" + this.calcAge(bd) + " év)"
            }
            return ""
        },
        isFirstVisitMonth: function() {
            const first_date = (this.notEmpty(this.beteg.data.firstvisitdate)) ? dayjs(this.beteg.data.firstvisitdate,'YYYY.MM.DD') : this.today
            const today_before = this.today.subtract(31, 'days')

            return today_before < first_date
        },
        isMoreThanSixMonth: function() {
            const first_date = (this.notEmpty(this.beteg.data.firstvisitdate)) ? dayjs(this.beteg.data.firstvisitdate,'YYYY.MM.DD') : this.today
            const today_before = this.today.subtract(6, 'months')

            return today_before > first_date
        },
        numberOfMonths: function() {
            const first_date = (this.notEmpty(this.beteg.data.firstvisitdate)) ? dayjs(this.beteg.data.firstvisitdate,'YYYY.MM.DD') : this.today
            let today_before = this.today.startOf('month')
            let ret = 1
            while(today_before > first_date && ret <= 5){ // 5. hónap után nem vagyunk kiváncsiak, hogy pontosan mennyi
                ret++
                today_before = today_before.subtract(1, 'month')
            } 

            return ret
        },
        isVisibleVisit12: function() {
            if(this.User.role == 'ADMIN') 
                return true

            // Ha K85H0, akkor csak 1 db 6000-es lehet
            // Ha első hónapban nincs még két vizit
            // vagy adott hónapban van már egy vizit vagy tanácsadás
            // 6. hónaptól nem lehetséges

            if(!this.isPatientActive || this.isMoreThanSixMonth){
                return false
            }else 
            if(this.isFirstVisitMonth){
                const visittype = this.beteg.x_visittypes.find(v => v.category == 'VIZIT_IDOSZAK_1')
                const visits = this.beteg.visit.filter(v => v.visittypeid == visittype.id)
                const maxVisit = (this.beteg.data.type == 'S' || this.isK85H0) ? 1 : 2 // Alapvetően 2 lehet, ha Heves Hasnyálmirigy gyulladás vagy Szoc.otthon, akkor 1
                return visits.length < maxVisit
            }else{
                if(this.beteg.data.type == 'S' || this.isK85H0) return false // SZOC otthonnál, Heves Hasnyálmirigy gyulladásnál csak első hónapban lehet

                // 3. és 5. hónapban lehet csak
                let ret = (this.numberOfMonths == 3 || this.numberOfMonths == 5) 

                // és még nincs másik
                if(ret){ // Vagyis még nem volt Vizit ebben a hónapban
                    const visittype_2      = this.beteg.x_visittypes.find(v => (v.category == 'VIZIT_IDOSZAK_2'))
                    const visits = this.beteg.visit.filter( v => 
                                            v.visittypeid == visittype_2.id && v.date >= dayjs().format('YYYY.MM.01')
                                        ) 
                    ret = visits.length == 0
                }
                return ret
            }
        },
        isVisibleVisitAdvisory: function() {
            if(this.User.role == 'ADMIN') 
                return true
            if(!this.isPatientActive || this.beteg.data.type == 'S' || this.isK85H0)  // Lezárt betegnél, SZOC otthonnál, Heves Hasnyálmirigy gyulladásnál nincs ilyen
                return false 

            if(this.isFirstVisitMonth || this.isMoreThanSixMonth){
                return false
            }else{
                // 2. és 4. hónapban lehet csak
                let ret = (this.numberOfMonths == 2 || this.numberOfMonths == 4)

                // és még nincs másik
                if(ret){ // Vagyis még nem volt Tanácsadás ebben a hónapban
                    const visittype_tavoli = this.beteg.x_visittypes.find(v => (v.category == 'VIZIT_TAVOLI'))
                    const visits =  this.beteg.visit.filter( v => 
                                        v.visittypeid == visittype_tavoli.id && v.date >= dayjs().format('YYYY.MM.01')
                                    ) 
                    ret = visits.length == 0
                }
                //const visittype_2      = this.beteg.x_visittypes.find(v => (v.category == 'VIZIT_IDOSZAK_2'))
                
                return ret
            }
        },
        visit12Tooltip: function() {
            return !this.isVisibleVisit12 ? 'Jelenleg nem adható hozzá új vizit' : ''
        },
        visitAdvisoryTooltip: function() {
            return !this.isVisibleVisitAdvisory ? 'Jelenleg nem adható hozzá új tanácsadás' : ''
        },
        isK85H0: function() {
            if(this.beteg.illness)
                return !!this.beteg.illness.find(i => i.bno == "K85H0")
            else 
                return false
        },
        KCAL: function() {
            if(this.notEmpty(this.beteg.data.kcal_day))
                return this.beteg.data.kcal_day + " kcal/nap | " + this.beteg.data.kcal_month + " kcal/hó"
            else
                return ''
        },
        BMI: function() {
            if(this.notEmpty(this.beteg.data.birthdate)
               && this.calcAge(this.beteg.data.birthdate.replaceAll(".", "-")) >= 18 
               && this.beteg.data.weight > 0 && this.beteg.data.height > 0 )
            {
                let num = this.beteg.data.weight / ( this.beteg.data.height * this.beteg.data.height / 10000)
                return num.toFixed(2)
            }
            else
                return "-"
        }
    },
    watch: {
        isOpenPatientDialog: function(val) {
            if(!val && this.dialogData.saved){
                this.beteg.data = this.dialogData 
            }
        },
        isOpenSocHomeDialog: function(val) {
            if(!val && this.dialogData.saved){
                this.beteg.data = this.dialogData 
            }
        },
        isOpenDoctorDialog: function(val) {
            if(!val && this.dialogData.saved){
                this.beteg.doctor = JSON.parse(JSON.stringify(this.dialogData.doctor))
                this.dialogData = {}
            }
        },
        isOpenStatusDialog: function(val) {
            if(!val && this.dialogData.saved){

                const newStatus = JSON.parse(JSON.stringify(this.dialogData))

                const i = this.beteg.status.findIndex(s => s.id === this.dialogData.id);
                if(parseInt(this.beteg.data.kcal_day) != parseInt(this.dialogData.kcal_day) && (i==-1 || i==0)){
                    this.beteg.data.kcal_day = this.dialogData.kcal_day
                    this.beteg.data.kcal_month = this.dialogData.kcal_month
                    this.beteg.data.height = this.dialogData.height
                    this.beteg.data.weight = this.dialogData.weight
                }
                if(i>=0)
                    this.beteg.status[i] = newStatus
                else
                    this.beteg.status.unshift(newStatus)

                this.dialogData = {}

            }
            if(!val && this.$route.query.new){
                this.$router.replace({'query': null})
            }

        },
        isOpenStatusCloseDialog: function(val) {
            if(!val && this.dialogData.saved){
                const i = this.beteg.status.findIndex(s => s.id === this.dialogData.id);
                if(i>=0)
                    this.beteg.status[i] = this.dialogData 
                else
                    this.beteg.status.unshift(this.dialogData)
                this.dialogData = {}
            }
        },
        isOpenIllnessDialog: function(val) {
            if(!val){
                const i = this.beteg.illness.findIndex(s => s.id === this.dialogData.id);
                if(this.dialogData.saved){
                    if(i>=0)
                        this.beteg.illness[i] = this.dialogData 
                    else
                        this.beteg.illness.push(this.dialogData)
                }else
                if(this.dialogData.deleted){
                    this.beteg.illness.splice(i, 1)
                }
                this.dialogData = {}
            }
        },
        isOpenOrderDialog: function(val) {
            if(!val){

                let index = -1
                if(this.dialogData.id && this.beteg.order_list != undefined){
                    index = this.beteg.order_list.findIndex(o => o.id == this.dialogData.id)
                }

                if(this.dialogData.saved){
                    let new_order = {}
                    new_order.saved = null
                    new_order.id = this.dialogData.id
                    new_order.evtype = this.dialogData.evtype
                    new_order.order_date = this.dialogData.order_date
                    new_order.status = this.dialogData.status
                    new_order.fulfil_date = (this.dialogData.fulfil_date==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? null : this.dialogData.fulfil_date
                    new_order.order_item_id = this.dialogData.item.id
                    new_order.fulfil_item_id = (this.dialogData.fulfil_item_id==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? this.dialogData.item.id : this.dialogData.fulfil_item_id
                    new_order.order_item_name = this.dialogData.item.name 
                    new_order.fulfil_item_name = (this.dialogData.fulfil_item_name==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? this.dialogData.item.name : this.dialogData.fulfil_item_name
                    new_order.order_amount = this.dialogData.amount
                    new_order.fulfil_amount = (this.dialogData.fulfil_amount==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? this.dialogData.amount : this.dialogData.fulfil_amount
                    new_order.homecarer_id = this.dialogData.homecarer_id
                    new_order.homecarer = this.dialogData.homecarer
                    new_order.pharmacy_id = this.dialogData.pharmacy.id 
                    new_order.pharmacy = this.dialogData.pharmacy.name 
                    new_order.homecarer_note = this.dialogData.homecarer_note
                    new_order.pharmacy_note = this.dialogData.pharmacy_note
                    new_order.manager_note = this.dialogData.manager_note
                    if(this.User.role=='ADMIN')
                        new_order.manager_note = this.dialogData.note
                    else 
                    if(this.User.role=='CARER')
                        new_order.homecarer_note = this.dialogData.note
                    else             // PHARMACY -- Ami itt elvileg nem lehet
                        new_order.pharmacy_note = this.dialogData.note
                                    
                    if(index >= 0){ // Vagyis módosítás
                        this.beteg.order_list[index] = new_order
                        this.beteg.order[index] = new_order
                    }else{
                        new_order.order_date = dayjs().format('YYYY.MM.DD')
                        new_order.status = '1'
                        this.beteg.order_list.unshift(new_order)
                        this.beteg.order.unshift(new_order)
                    }
                }else
                if(this.dialogData.deletedLogically){
                    this.beteg.order_list[index].status = "9"
                    this.beteg.order[index].status = "9"
                }else
                if(this.dialogData.deleted){
                    this.beteg.order_list.splice(index, 1)
                    this.beteg.order.splice(index, 1)
                }
                // TODO (Ellenőrizni, hogy fennáll-e még:) Ez utóbbi valamiért nem jó, újraszerkesztésnél ott vannak a régi értékek, a dialogData watch sem fut a komponensben újra
                this.dialogData = {}
            }
        },
        isOpenOrderConsultDialog: function(val) {
            if(!val && this.dialogData.saved){
                const index = this.beteg.order_list.findIndex(o => o.id == this.dialogData.id)
                this.beteg.order_list[index].visitdate = this.dialogData.visitdate
                this.beteg.order_list[index].visitcost = this.dialogData.visitcost
                this.beteg.order_list[index].visit_note = this.dialogData.visit_note
            }
        },
        isOpenVisitDialog: function(val) {
            if(!val){
                let index = -1
                if(this.dialogData.id && this.beteg.visit_list != undefined){
                        index = this.beteg.visit_list.findIndex(o => o.id == this.dialogData.id)
                }

                if(this.dialogData.saved){
                    let new_visit = JSON.parse(JSON.stringify(this.dialogData))
                    new_visit.saved = null
                    new_visit.type = new_visit.visittype.visitname
                    new_visit.status = new_visit.visit_status

                    if(index >= 0){ // Vagyis módosítás
                        this.beteg.visit_list[index] = new_visit
                        this.beteg.visit[index] = new_visit
                    }else{
                        this.beteg.visit_list.unshift(new_visit)
                        this.beteg.visit.unshift(new_visit)
                    }
                }else
                if(this.dialogData.deletedLogically){
                    this.beteg.visit_list[index].status = "9"
                    this.beteg.visit[index].status = "9"
                }else
                if(this.dialogData.deleted){
                    this.beteg.visit_list.splice(index, 1)
                    this.beteg.visit.splice(index, 1)
                }
                // TODO (Ellenőrizni, hogy fennáll-e még:) Ez utóbbi valamiért nem jó, újraszerkesztésnél ott vannak a régi értékek, a dialogData watch sem fut a komponensben újra
                //this.dialogData = {}

            }
        }
    }
}
</script>

<style lang="postcss" scoped>
	@media screen and (max-width: 960px) {
		::deep() .p-datatable {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: left;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
			}
		}
	}

    .beteg-adat-container{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .beteg-adat-container span {
        -webkit-user-select: all;  /* Chrome all / Safari all */
        -moz-user-select: all;     /* Firefox all */
        -ms-user-select: all;      /* IE 10+ */
        user-select: all;          /* Likely future */   
    }
    .beteg-adat-container > li {
        padding: 10px 14px;
        border-bottom: 1px solid #dbdbdb;
    }
    .beteg-adat-container > li:hover {
        background-color: #f5f5f5;
    }
    /*.beteg-adat-container > li:first-child {
        /*margin-top: 10px;
    }*/
    .beteg-adat-container .p-checkbox {
        vertical-align: middle;
        margin-right: 5px;
    }
    .beteg-adat-container .task-name {
        vertical-align: middle;
    }
    .beteg-adat-container i {
        color: rgba(0, 0, 0, 0.6);
        float: left;
        margin-right: 1rem;
    }
    .details-panel-expander {
        height: 2.7rem;
        text-align: center;
        background-color: #f5f5f5;
        cursor: pointer;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .details-panel-expander:hover {
        background-color: #dedede;
    }
    .details-panel-expander i {
        display: block;
        float: none;
        line-height: 2.7rem;
    }
    .details-panel-no-expander {
        height: 2.7rem;
        background-color: #f5f5f5;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .panel-header-button-1 {
        margin-top: -45px;
        margin-right: 10px;
        float: right;
    }

    .panel-header-button-1 .p-button:not(:last-of-type),
    .panel-header-button-1 span,
    .panel-header-button-1 div {
        margin-right: 10px;
    }

    .details-panel {
        overflow: hidden;
    }

    .details-panel table {
    width: 100%;
    border-collapse: collapse;
    }
    .details-panel table th {
    font-weight: 700;
    text-align: left;
    padding: 1px 5px;
    }
    .details-panel table tbody tr {
    border-top: 1px solid #bdbdbd;
    }
    .details-panel table tbody tr:hover {
        background-color: #f5f5f5;
    }
    .details-panel table tbody tr img {
    width: 36px;
    height: 36px;
    }
    .details-panel table tbody tr td {
    padding: 1px 5px;
    }
    .details-panel table thead tr th:nth-child(1),
    .details-panel table tbody tr td:nth-child(1) {
    font-weight: 700;
    padding-left: 20px;
    }
    .details-panel table tbody tr td:last-child {
    text-align: right;
    padding-right: 10px;
    }
    .details-panel table tbody tr td button {
    margin-left: 10px;
    }
    .details-panel table tbody tr td .up-arrow {
    color: #cddc39;
    }
    .details-panel table tbody tr td .down-arrow {
    color: #e91e63;
    }

    .details-panel {
        padding: 0px;
        padding-top: 14px;
    }

    .details-panel .betegseg-adat-bno {
        width: 50px;
        display: inline-block;
        vertical-align: top;
    }

    .details-panel .betegseg-adat-nev {
        display: inline-block;
        width: 400px;
    }

    .p-datatable ::deep() .p-datatable-thead > tr > th {
        padding: 1px;
        font-weight: 700;
    }
    .p-datatable ::deep() .p-datatable-tbody > tr {
        cursor: pointer;
    }
    .p-datatable ::deep() .p-datatable-tbody > tr > td {
        padding: 1px 1px 1px 0px;
    }
    .p-datatable ::deep() .p-datatable-row-expansion {
        cursor: default !important;
    }

    .p-badge{border-radius:2px;padding:.25em .5rem;text-transform:uppercase;font-weight:700;font-size:11px;letter-spacing:.3px; background-color:#c8e6c9;color:#256029}
    .p-badge.status-1{background-color:#b3e5fc;color:#23547b}
    .p-badge.status-2{background-color:#feedaf;color:#8a5340}
    .p-badge.status-5{background-color:#f3fff3;color:#47944c}
    .p-badge.status-6{background-color:#a5a2a2;color:#eeeeee}
    .p-badge.status-8{background-color:#ffcdd2;color:#c63737}
    .p-badge.status-9{background-color:#dfdfdf;color:#c63737}
    .p-badge.status-0{background-color:#eccfff;color:#694382}

    .p-badge.visit-status-K{background-color:#b3e5fc;color:#23547b}
    .p-badge.visit-status-J{background-color:#feedaf;color:#8a5340}
    .p-badge.visit-status-T{background-color:#f3fff3;color:#47944c}
    .p-badge.visit-status-A{background-color:#a5a2a2;color:#eeeeee}
    .p-badge.visit-status-X{background-color:#ffcdd2;color:#c63737}

    /* visitTipus: {'T':'Teljesítve', 'A':'Automata', 'J':'Jóváhagyva', 'K':'Kérelmezve', 'X':'Elutasítva'}, */

</style>

<style lang="postcss">
.details-panel .p-panel {
  border: 0 none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    min-height: unset;
}
.details-panel .p-panel .p-panel-content {
  padding: 10px 0 0 0 !important;
}
#beteg-adat-panel .p-panel-title {
    font-weight: bold;
}
.p-panel-content li {
    height: auto;
    min-height: 38px;
}
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}

.l-row-button {
    visibility: hidden;
}
tr:hover .l-row-button {
    visibility: visible;
}
.l-table-multiline > tbody > tr > td:first-child {
    padding-left: 1rem !important;
}
.l-table-multiline > tbody > tr > td {
    vertical-align: top;
    padding-top: 0.5rem !important;
}
.p-tag {
    margin: 0.2rem;
}
.l-visit-table tr {
    height: 40px;
}
.l-btn-order-cons {
    margin: 0.7rem 0 0 2rem !important;
}
.p-badge.vizitlap{
    background-color:#c8e6c9;
    color:#256029;
    margin-top: .2rem;
}
</style>
